<template>
    <Component
        :is="componentInstance"
        v-bind="$attrs"
        :value="value"
        :hide-null="hideNull"
        :get-data-vuex="getUsers"
        :placeholder="placeholder"
        :multiple="multiple"
        class="white"
        field="login"
        @[event]="$emit(event, $event)">
        <template #text="option">
            <UserOutput
                :user="option.option"
                :int-id="$saas.visibleLoginWebmaster || intId"
                :login="$saas.visibleLoginWebmaster">
            </UserOutput>
        </template>
        <template #selected-option="option">
            <UserOutput
                :user="option.option"
                :int-id="$saas.visibleLoginWebmaster || intId"
                :login="$saas.visibleLoginWebmaster">
            </UserOutput>
        </template>
    </Component>
</template>

<script>
  import UserOutput from "@/components/Common/Output/UserOutput";
  import { UsersSelect } from "@core/mixins/select/usersSelect";
  import { SET_EMPTY } from "@core/store/action-constants";

  export default {
    name: "ActualWebmastersSelect",
    mixins: [UsersSelect],

    components: { UserOutput },

    props: {
      routeParam: {
        type: String,
        default: "webmasterId"
      },

      namespaceModule: {
        type: String,
        default: "webmastersFilters"
      },

      hideNull: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      async getUsers (label = "", isLoading = false) {
        if (!isLoading) {
          this.$store.commit(`${ this.namespaceModule }/${ SET_EMPTY }`);
        }
        const isActualId = /^\d+$/.test(label) && !label.startsWith("0");
        label = isActualId ? Number(label) : label;
        const labelName = isActualId ? "actualIntId" : "loginOrIntId";

        await this.getFiltersCommon(this.namespaceModule, this.list, this.$route.query[this.routeParam] ?? this.value,
                                    this.routeParam, labelName, label, isLoading, this.filterOptions);
        return this.list;
      }
    }
  };
</script>

<style scoped>

</style>